/* eslint-disable max-len */
import { TextEditor, View, Text, UpdateCommentParams } from '@/components'
import { AppForms, React, Settings } from '@/app'
import { DecisionComment } from '@/types'
import { FormItemWrapper } from './FormItemWrapper'
import { useMemo } from '@codeleap/common'
import { useMemoizedForm } from '@/utils'

export type CommentsFormProps = {
  comments: DecisionComment[]
  publicationId: number
  disabled?: boolean
  updateComment: (comment: UpdateCommentParams) => void
}

export type CommentProps = DecisionComment & Pick<CommentsFormProps, 'publicationId' | 'disabled'> & { position: number; onChange: CommentsFormProps['updateComment'] }
export type EditorCommentProps = { id: string; comment: DecisionComment['editor_comment']; disabled?: boolean; onChange: (comment: UpdateCommentParams) => void }

const Comment = ({ id, comment, editor_comment, reviewer_email, reviewer_name, reviewer_id, disabled, publicationId, position, onChange }: CommentProps) => {
  const form = useMemoizedForm(AppForms.decisionForm, { validateOn: 'change' })

  return (
    <FormItemWrapper
      reviewer={{ reviewer_email, reviewer_name, reviewer_id }}
      publicationId={publicationId}
      position={position}
      isEditor={!!editor_comment}
      showMoreInfoIcon={reviewer_email !== Settings.AIReviewer.email}
      isAi={reviewer_email === Settings.AIReviewer.email}
    >
      <View variants={['row', 'gap:1']}>
        <TextEditor
          textEditorProps={{
            toolbarComponent: null, variants: ['reviewOverviewForm', 'multiline', 'flat:disabled'],
            ...form.register('comment'),
          }}
          disabled={disabled}
          value={comment || editor_comment}
          onChangeValue={({ editor }) => {
            const editorValue = editor?.getText?.()
            const isValid = editorValue?.length <= Settings.CoverLetterConfigs.max
            onChange({ id, newValue: editor?.getHTML?.(), isValid })
            form.setFieldValue('comment', editorValue)
          }}
          placeholder='Text your message here'
        />
      </View>
    </FormItemWrapper>

  )
}

const EditorComment = ({ id, comment, onChange, disabled }: EditorCommentProps) => {
  const editorCommentForm = useMemoizedForm(AppForms.decisionForm, { validateOn: 'change' })

  return (
    <View variants={['column', 'gap:1']}>
      <Text variants={['p2', 'neutral-8']} text='Editor comment' />
      <TextEditor
        placeholder='Text your message here'
        disabled={disabled}
        value={comment || ''}
        onChangeValue={({ editor }) => {
          const editorValue = editor?.getText?.()
          const isValid = editorValue?.length <= Settings.CoverLetterConfigs.max
          onChange({ id, newValue: editor?.getHTML?.(), isValid })

          editorCommentForm.setFieldValue('editorComment', editor?.getText?.())
        }}
        toolbarComponentProps={{
          excludeIds: ['image', 'link', 'fileComponent'],
          variants: ['reviewCommentModal', 'bg:neutral1', 'border-radius:tiny'],
          hide: disabled,
        }}
        textEditorProps={{
          variants: ['reviewOverviewForm', 'multiline', 'flat:disabled', !disabled && 'noRadiusTop', disabled && 'flat'],
          ...editorCommentForm.register('editorComment'),
        }}
      />
    </View>
  )
}

export const CommentsForm = (props: CommentsFormProps) => {
  const {
    comments,
    publicationId,
    disabled,
    updateComment,
  } = props

  const filteredComments = useMemo(() => {
    return comments?.reduce((acc, comment) => {
      if (comment.type === 'reply') {
        acc.currentEditorComment = comment.editor_comment
      } else {
        acc.otherComments.push(comment)
      }
      return acc
    },
    { currentEditorComment: '', otherComments: [] })
  }, [comments?.length])

  return (
    <View variants={['column']}>
      <View variants={['backgroundColor:neutral2', 'column', 'marginBottom:2', 'border-radius:small', 'gap:3', 'padding:2']}>
        {filteredComments?.otherComments?.map((comment, key) => {
          return (
            <Comment
              key={key}
              position={key}
              publicationId={publicationId}
              onChange={updateComment}
              disabled={disabled}
              {...comment}
            />
          )
        })}

        {(disabled && !filteredComments?.currentEditorComment) ? null : (
          <EditorComment
            comment={filteredComments?.currentEditorComment}
            onChange={updateComment}
            disabled={disabled}
            id={'editorComment'}
          />
        )}
      </View>
    </View>

  )
}
