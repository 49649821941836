import { useWidgetIsReady } from '@livechat/widget-react'
import { AppIcon, React } from '@/app'
import { Button, SubmitTicketModal, OptionsDropDown } from '@/components'
import { Navigation } from '@/utils'
import { useBooleanToggle, useMemo } from '@codeleap/common'
import { useLocation } from '@reach/router'

const menuOptions = [
  {
    text: 'Help guide',
    onPress: () => Navigation.navigate('Help.Guide'),
    disabled: false,
    pathname: '/help/',
  },
  {
    text: 'FAQ',
    onPress: () => Navigation.navigate('Help.FAQ'),
    disabled: false,
    pathname: '/help/frequently_asked_questions/',
  },
  {
    text: 'Submit ticket',
    onPress: () => SubmitTicketModal.open(),
    disabled: false,
  },
]

export const HelpCenterMenu = () => {
  const [visible, toggle] = useBooleanToggle(false)
  const isWidgetReady = useWidgetIsReady()

  const location = useLocation()

  const liveChatOption = {
    text: 'Live chat',
    onPress: () => {
      window.LiveChatWidget.call('show')
      window.LC_API.open_chat_window()
    },
    disabled: !isWidgetReady,
  }

  const _menuOptions = [...menuOptions, liveChatOption]

  const options = useMemo(() => {
    return _menuOptions.map((option) => {

      // @ts-ignore
      const isSelected = location.pathname === option.pathname

      return (
        <Button
          text={option.text}
          debugName={`helpCenterMenu:${option.text}`}
          onPress={() => {
            option.onPress()
            toggle(false)
          }}
          variants={['roleSelector']}
          disabled={option.disabled}
          selected={isSelected}
        />
      )
    })
  }, [isWidgetReady])

  return (
    // <View>
    <OptionsDropDown
      variants={['optionText:primary5', 'optionLines:none', 'textIcon:reverse', 'textIcon:spacing1']}
      debugName={'Help Center Menu'}
      tooltipProps={{
        closeOnClickOutside: true,
        content: options,
        variants: ['roleSelector'],
        visible,
        toggle,
      }}
      id={'helpCenterMenu'}
      icon={'chevron-down' as AppIcon}
      options={[]}
      text={'Help center'}
    />
    // {/* </View> */}
  )
}
