import { variantProvider } from '@/app'
import { IconPlaceholder } from '@codeleap/common'
import * as LibComponents from '@codeleap/web'
import { defaultStyles } from '@codeleap/web'
import * as StyleSheets from '../app/stylesheets'
import { ActivityIndicatorCP } from './ActivityIndicator'
export * from './Widgets'
export * from './ComponentShowCase'

export const variants = {
  ...defaultStyles,
  Alert: StyleSheets.AppAlertStyles,
  Checkbox: StyleSheets.AppCheckboxStyles,
  RadioInput: StyleSheets.AppRadioInputStyles,
  Touchable: StyleSheets.AppTouchableStyles,
  Button: StyleSheets.AppButtonStyles,
  Icon: StyleSheets.AppIconStyles,
  ActivityIndicator: StyleSheets.AppActivityIndicatorStyles,
  Select: StyleSheets.AppSelectStyles,
  Text: StyleSheets.AppTextStyles,
  View: StyleSheets.AppViewStyles,
  Modal: StyleSheets.AppModalStyles,
  Drawer: StyleSheets.AppDrawerStyles,
  TextInput: StyleSheets.AppTextInputStyles,
  Image: StyleSheets.AppImageStyles,
  Link: StyleSheets.LinkStyles,
  Logo: StyleSheets.LogoStyles,
  Page: StyleSheets.PageStyles,
  Overlay: StyleSheets.AppOverlayStyles,
  List: StyleSheets.AppListStyles,
  Avatar: StyleSheets.AvatarStyles,
  CenterWrapper: StyleSheets.CenterWrapperStyles,
  ActionIcon: StyleSheets.AppActionIconStyles,
  LoadingOverlay: StyleSheets.AppLoadingOverlayStyles,
  InputBase: StyleSheets.AppInputBaseStyles,
  Switch: StyleSheets.AppSwitchStyles,
  Slider: StyleSheets.AppSliderStyles,
  NumberIncrement: StyleSheets.AppNumberIncrementStyles,
  Tooltip: StyleSheets.AppTooltipStyles,
  Badge: StyleSheets.AppBadgeStyles,
  SegmentedControl: StyleSheets.AppSegmentedControlStyles,
  Pager: StyleSheets.AppPagerStyles,
  CardBase: StyleSheets.AppCardBaseStyles,
  Navigation: StyleSheets.NavigationStyles,
  EmptyPlaceholder: StyleSheets.AppEmptyPlaceholderStyles,
  OptionsDropDown: StyleSheets.OptionsDropDownStyles,
  Grid: StyleSheets.AppGridStyles,
  ListRow: StyleSheets.ListRowStyles,
  ListHeader: StyleSheets.ListHeaderStyles,
  Header: StyleSheets.HeaderStyles,
  Dropzone: StyleSheets.DropzoneStyles,
  Table: StyleSheets.TableStyles,
  TableRow: StyleSheets.TableRowStyles,
  PaginationButtons: StyleSheets.AppPaginationButtonStyles,
  TextEditor: StyleSheets.TextEditorStyles,
  ColorPicker: StyleSheets.ColorPickerStyles,
  SubHeader: StyleSheets.SubHeaderStyles,
  DatePicker: StyleSheets.AppDatePickerStyles,
  Placeholder: StyleSheets.PlaceholderStyles,
  JournalCard: StyleSheets.JournalCardStyles,
  JournalList: StyleSheets.JournalListStyles,
  SectionFilters: StyleSheets.SectionFiltersStyles,
  SortComponent: StyleSheets.Styles,
  ExpandableView: StyleSheets.ExpandableViewStyles,
  AvatarGroup: StyleSheets.AvatarGroupStyles,
  Toaster: StyleSheets.ToasterStyles,
  Toolbar: StyleSheets.ToolbarStyles,
  FilePreview: StyleSheets.FilePreviewStyles,
  Tag: StyleSheets.TagStyles,
}

LibComponents.Select.defaultProps.arrowIconName = 'chevrons-up-down' as IconPlaceholder
LibComponents.Select.defaultProps.selectedIcon = 'check' as IconPlaceholder
LibComponents.TextInput.defaultProps.visibleIcon = 'eye' as IconPlaceholder
LibComponents.TextInput.defaultProps.hiddenIcon = 'eye-off' as IconPlaceholder
LibComponents.SearchInput.defaultProps.searchIcon = 'search' as IconPlaceholder
LibComponents.SearchInput.defaultProps.clearIcon = 'x' as IconPlaceholder
LibComponents.Modal.defaultProps.closeIconName = 'x' as IconPlaceholder
LibComponents.ActivityIndicator.defaultProps.component = ActivityIndicatorCP as () => JSX.Element
LibComponents.List.defaultProps.refresh = false

LibComponents.Modal.defaultProps.scrollLock = true
LibComponents.Modal.defaultProps.autoIndex = true

const components = variantProvider.typeComponents({
  View: [LibComponents.View, variants.View],
  Icon: [LibComponents.Icon, variants.Icon],
  Text: [LibComponents.Text, variants.Text],
  Touchable: [LibComponents.Touchable, variants.Touchable],
  Scroll: [LibComponents.Scroll, variants.Scroll],
  ActivityIndicator: [
    LibComponents.ActivityIndicator,
    variants.ActivityIndicator,
  ],
  Button: [LibComponents.Button, variants.Button],
  ActionIcon: [LibComponents.ActionIcon, variants.ActionIcon],
  Modal: [LibComponents.Modal, variants.Modal],
  Checkbox: [LibComponents.Checkbox, variants.Checkbox],
  RadioInput: [LibComponents.RadioGroup, variants.RadioInput],
  SegmentedControl: [LibComponents.SegmentedControl, variants.SegmentedControl],
  Select: [LibComponents.Select, variants.Select],
  TextInput: [LibComponents.TextInput, variants.TextInput],
  Overlay: [LibComponents.Overlay, variants.Overlay],
  FileInput: [LibComponents.FileInput, {}],
  List: [LibComponents.List, variants.List],
  Drawer: [LibComponents.Drawer, variants.Drawer],
  LoadingOverlay: [LibComponents.LoadingOverlay, variants.LoadingOverlay],
  InputBase: [LibComponents.InputBase, variants.InputBase],
  Switch: [LibComponents.Switch, variants.Switch],
  Slider: [LibComponents.Slider, variants.Slider],
  NumberIncrement: [LibComponents.NumberIncrement, variants.NumberIncrement],
  Tooltip: [LibComponents.Tooltip, variants.Tooltip],
  Badge: [LibComponents.Badge, variants.Badge],
  Pager: [LibComponents.Pager, variants.Pager],
  EmptyPlaceholder: [LibComponents.EmptyPlaceholder, variants.EmptyPlaceholder],
  Grid: [LibComponents.Grid, variants.Grid],
  Dropzone: [LibComponents.Dropzone, variants.Dropzone],
  _TextEditor: [LibComponents.TextEditor, variants.TextEditor],
  ColorPicker: [LibComponents.ColorPicker, variants.ColorPicker],
  DatePicker: [LibComponents.DatePicker, variants.DatePicker],
  Tag: [LibComponents.Tag, variants.Tag],
})

const SelectWithGenerics = LibComponents.Select as <
  T extends string | number = string,
  Multi extends boolean = false
>(
  props: LibComponents.SelectProps<T, Multi>
) => JSX.Element

export const allComponents = {
  ...components,
  Select: SelectWithGenerics,
}

export const {
  View,
  Button,
  Text,
  Icon,
  Touchable,
  ActionIcon,
  Scroll,
  ActivityIndicator,
  Modal,
  Checkbox,
  RadioInput,
  Select,
  Overlay,
  LoadingOverlay,
  FileInput,
  List,
  Drawer,
  Switch,
  NumberIncrement,
  Tooltip,
  SegmentedControl,
  Pager,
  Grid,
  Dropzone,
  _TextEditor,
  ColorPicker,
  DatePicker,
  Tag,
  TextInput: DefaultTextInput,
} = allComponents

import { DebouncedInput } from './DebouncedInput'

export const TextInput = DebouncedInput as typeof allComponents.TextInput

// export const TextEditor = DebouncedEditor as typeof allComponents.TextEditor

export * from './Modals'
export * from './AppStatus'
export * from './PostCard'
export * from './CenterWrapper'
export * from './Header'
export * from './Header'
export * from './Avatar'
export * from './Footer'
export * from './Page'
export * from './Image'
export * from './Link'
export * from './Page'
export * from './Logo'
export * from './GlobalStyle'
export * from './Animations'
export * from './ActivityIndicator'
export * from './Cards'
export * from './SEO'
export * from './HTMLRender'
export * from './Navigation'
export * from './LanguageSelector'
export * from './Auth'
export * from './LoadingScreen'
export * from './OptionsDropdown'
export * from './PagePath'
export * from './Animations'
export * from './Table'
export * from './Permissions'
export * from './PaginationButtons'
export * from './Toolbar'
export * from './Reviewers'
export * from './Placeholder'
export * from './SubHeader'
export * from './SelectJournal'
export * from './ExpandableView'
export * from './Toaster'
export * from './Publication'
export * from './AvatarGroup'
export * from './Journals'
export * from './Breadcrumb'
export * from './AttachmentsDropzone'
export * from './HTMLRender'
export * from './EvaluatePublicationButtons'
export * from './JournalRecommendationInput'
export * from './Skeleton'
export * from './StatusTag'
export * from './Dashboard'
export * from './ImagePlaceholder'
export * from './UploadFiles'
export * from './PlacesAutocomplete'
export * from './PhoneInput'
export * from './Manuscripts'
export * from './PhoneTextInput'
export * from './ManuscriptStatus'
export * from './TextEditor'
export * from './SortModal'
export * from './FileUpload'
export * from './SectionFilters'
export * from './CropPickerModal'
export * from './HelpCenterMenu'

export default components

export type AppModal = typeof Modal
