import { ActivityIndicator, Button, Select, View, usePublicationForm, Text, Icon, RejectAndTransferManuscriptModal, ReviewsOverview } from '@/components'
import { getOsAlert, React, Theme } from '@/app'
import { AnyFunction, onUpdate, useMemo, useState } from '@codeleap/common'
import { APIClient } from '@/services'
import { AppStatus } from '@/redux'
import { Publication } from '@/types'

type EvaluatePublicationButtonsProps = {
    publication?: Publication['id']
    onAcceptPublication?: AnyFunction
    onRevisePublication?: AnyFunction
    onRejectPublication?: AnyFunction
    onWithdrawPublication?: AnyFunction
    showAccept?: boolean
    showReject?: boolean
    showRevise?: boolean
    disabled?: boolean
}

export const EvaluatePublicationButtons = (props: EvaluatePublicationButtonsProps) => {
  const {
    showAccept = true,
    showReject = true,
    showRevise = true,
    disabled = false,
  } = props

  const updatePublicationStatus = usePublicationForm(v => v.updatePublicationStatus)
  const isAccepted = usePublicationForm(v => v.isAccepted)
  const isRejected = usePublicationForm(v => v.isRejected)
  const isRejected_resubmit = usePublicationForm(v => v.isRejected_resubmit)
  const isRejected_transfer = usePublicationForm(v => v.isRejected_transfer)
  const isWithdraw = usePublicationForm(v => v.isWithdraw)
  const isRevision_requested = usePublicationForm(v => v.isRevision_requested)

  const { isEditor, isPublisher } = APIClient.Session.useSession()

  const { data: publication, query: publicationQuery } = APIClient.Publications.useRetrieve({ id: props?.publication, ignore_badges: true })
  const allJournals = APIClient.Journals.journalsManager.useList({})

  const canDecide = !!publication && (isEditor || isPublisher)
  const canAccept = canDecide && !isAccepted
  const canReject = canDecide && !isRejected
  const canWithdraw = canDecide && !isWithdraw
  const canRevise = canDecide && !isRevision_requested
  const canRejectAndTransfer = allJournals?.items?.find?.(journal => journal?.id !== publication?.journal?.id)

  const onOpenTransferManuscriptModal = async () => {
    ReviewsOverview.close()

    RejectAndTransferManuscriptModal.open()
  }

  const onRejectAndResubmit = async () => {
    AppStatus.set('loading')
    try {
      const updatedPublication = await updatePublicationStatus('rejected_resubmit')
      if (updatedPublication) {
        await props?.onRejectPublication?.({ publication: updatedPublication })
        ReviewsOverview.close()

        AppStatus.set('done')
      }
    } catch (e) {
      AppStatus.set('idle')
      getOsAlert('resubmitPublicationError')
    }
  }

  const onRejectReview = async () => {
    AppStatus.set('loading')
    try {
      const updatedPublication = await updatePublicationStatus('rejected')
      if (updatedPublication) {
        await props?.onRejectPublication?.({ publication: updatedPublication })
        AppStatus.set('done')
      }
    } catch (e) {
      AppStatus.set('idle')
      getOsAlert('rejectPublicationError')
    }
  }

  const RejectButtonColors = {
    rejected: 'destructive2',
    rejected_transfer: 'alert4',
    rejected_resubmit: 'alert3',
  }

  const isRejectAndResubmitDisabled = isRejected_resubmit || disabled
  const isRejectAndTransferDisabled = isRejected_transfer || disabled
  const isRejectDisabled = !canReject || disabled

  const rejectOptions = useMemo(() => [
    {
      value: 'rejected_resubmit',
      label: 'Reject and invite resubmission',
      itemProps: {
        disabled: isRejectAndResubmitDisabled,
        onPress: () => getOsAlert('onOpenRejectReplicaModal', { onRejectReview: onRejectAndResubmit }),
        style: { backgroundColor: Theme.colors.light[isRejectAndResubmitDisabled ? 'neutral2' : RejectButtonColors.rejected_resubmit] },
      },
    },
    {
      value: 'rejected_transfer',
      label: 'Reject and transfer',
      itemProps: {
        disabled: isRejectAndTransferDisabled,
        onPress: onOpenTransferManuscriptModal,
        style: { backgroundColor: Theme.colors.light[isRejectAndTransferDisabled ? 'neutral2' : RejectButtonColors.rejected_transfer] },
      },
    },
    {
      value: 'rejected',
      label: 'Reject',
      itemProps: {
        disabled: isRejectDisabled,
        onPress: () => getOsAlert('onOpenRejectReplicaModal', { onRejectReview }),
        style: { backgroundColor: Theme.colors.light[isRejectDisabled ? 'neutral2' : RejectButtonColors.rejected] },
      },
    },
  ], [canReject, canRejectAndTransfer, disabled, onRejectAndResubmit, onOpenTransferManuscriptModal])

  onUpdate(() => {
    if (!publication.status.startsWith('rejected')) {
      onSelectRejectStatus(rejectOptions.find(item => item.value === 'rejected'))
    }
  }, [publication.status])

  const rejectInitialState = useMemo(() => {
    return rejectOptions.find((item) => item.value === (publication.status.startsWith('rejected') ? publication.status : 'rejected'))
  }, [publication.status, rejectOptions])

  const [rejectStatus, onSelectRejectStatus] = useState(rejectInitialState)

  const RejectButtonLabel = () => (
    <View variants={['row', 'marginRight:2', 'gap:1', 'alignCenter']}>
      <Icon name={'x-circle'} variants={['small', 'neutral1']} debugName={'Reject Button - x icon'}/>
      <View variants={['gap:2']}>
        <Text variants={[`p1`, 'color:neutral1', 'bold']} text={rejectStatus.label}/>
        <Text variants={[`p1`, 'color:neutral1']} text={`|`}/>
      </View>
    </View>
  )

  const onChangeRejectStatus = (item) => {
    const currentItem = rejectOptions.find(option => option.value === item)
    onSelectRejectStatus(currentItem)
  }

  const onAcceptReview = async () => {
    AppStatus.set('loading')
    try {
      const updatedPublication = await updatePublicationStatus('accepted')
      if (updatedPublication) {
        await props?.onAcceptPublication?.({ publication: updatedPublication })
        AppStatus.set('done')
      }
    } catch (e) {
      AppStatus.set('idle')
      getOsAlert('acceptPublicationError')
    }
  }

  const onSubmitEditorReview = async () => {
    AppStatus.set('loading')
    try {
      const updatedPublication = await updatePublicationStatus('revision_requested')
      if (updatedPublication) {
        await props?.onRevisePublication?.({ publication: updatedPublication })
        AppStatus.set('done')
      }
    } catch (e) {
      AppStatus.set('idle')
      getOsAlert('rejectPublicationError')
    }
  }

  const onWithdrawPublication = async () => {
    AppStatus.set('loading')
    try {
      const updatedPublication = await updatePublicationStatus('withdraw')
      if (updatedPublication) {
        await props?.onWithdrawPublication?.({ publication: updatedPublication })
        ReviewsOverview.close()
        AppStatus.set('done')
      }
    } catch (e) {
      AppStatus.set('idle')
      getOsAlert('withdrawPublicationError')
    }
  }

  if (publicationQuery.isLoading) {
    return (
      <View variants={['center', 'fullWidth']}>
        <ActivityIndicator debugName='Loading Publication'/>
      </View>
    )
  }

  return (
    <View variants={['gap:2', 'center', 'fullWidth', 'wrap']}>
      {showAccept && <Button
        variants={['large', 'backgroundColor:positive3', 'border-radius:tiny']}
        icon='check-circle'
        text={'Accept'}
        debugName={`Review Overview Modal - Accept Publication`}
        disabled={!canAccept || disabled}
        onPress={() => getOsAlert('onOpenAcceptReplicaModal', { onAcceptReview })}
      />}
      {showRevise && <Button
        variants={['large', 'backgroundColor:warning3', 'border-radius:tiny']}
        icon='eyeglass-2'
        text={'Revise'}
        debugName={`Review Overview Modal - Revise Publication`}
        disabled={!canRevise || disabled}
        onPress={() => getOsAlert('onConfirmEditorReview', { onSubmitEditorReview })}
      />}
      {showReject && (
        <Select
          value={rejectStatus}
          onValueChange={onChangeRejectStatus}
          options={rejectOptions}
          formatOptionLabel={RejectButtonLabel}
          debugName={'Review Overview Modal - Reject Publication'}
          variants={['rejectButtonsSelect', !disabled && `bg:${RejectButtonColors[rejectStatus?.value]}`]}
          selectedIcon={null}
          menuPlacement='top'
          disabled={disabled}
        />
      )}
      <Button
        variants={['large', 'withdrawButton']}
        icon='arrow-bar-left'
        text={'Withdraw'}
        debugName={`Review Overview Modal - Reject Publication`}
        disabled={!canWithdraw || disabled}
        onPress={() => getOsAlert('onOpenWithdrawModal', { onWithdrawPublication })}
      />
    </View>
  )
}
