import { variantProvider } from '../theme'

export type ToolbarComposition = 'toolbarWrapper' | 'toolbarGroup' | 'option' | 'option:active' | 'option:disabled'

const createToolbarStyle = variantProvider.createVariantFactory<ToolbarComposition>()

const ICON_SIZE = 32

export const ToolbarStyles = {
  default: createToolbarStyle((theme) => ({
    toolbarWrapper: {
      ...theme.spacing.padding(2),
      ...theme.spacing.gap(2),
      ...theme.presets.flex,
      ...theme.presets.justifyStart,
      ...theme.presets.alignCenter,
      flexWrap: 'wrap',
      borderTopLeftRadius: theme.borderRadius.small,
      borderTopRightRadius: theme.borderRadius.small,
      ...theme.border.neutral5({ width: 1 }),
      ...theme.presets.relative,
    },
    toolbarGroup: {
      backgroundColor: theme.colors.neutral1,
      borderRadius: theme.borderRadius.tiny,
      overflow: 'hidden',

    },
    option: {
      maxWidth: ICON_SIZE,
      maxHeight: ICON_SIZE,
      width: '100%',
      height: '100%',
      ...theme.spacing.padding(1),
      background: theme.colors.neutral2,
      ...theme.border.neutral1({ width: 1, directions: ['left', 'right'] }),
    },
    'option:active': {
      background: theme.colors.primary5,
      'svg, path': {
        color: theme.colors.neutral1 + ' !important',
        stroke: theme.colors.neutral1 + ' !important',
      },
    },
    'option:disabled': {
      // backgroundColor: theme.colors.neutral2,
      // color: theme.colors.destructive2,
    },
  })),
  disclaimerToolbar: createToolbarStyle((theme) => ({
    toolbarWrapper: {
      ...theme.spacing.padding(2),
      ...theme.spacing.gap(2),
      ...theme.presets.justifyStart,
      ...theme.presets.alignCenter,
      flexWrap: 'wrap',
      borderTopLeftRadius: theme.borderRadius.small,
      borderTopRightRadius: theme.borderRadius.small,
      ...theme.border.neutral5({ width: 1 }),
      ...theme.presets.relative,
      maxHeight: ICON_SIZE + theme.spacing.value(4),
      [theme.media.down('mid')]: {
        maxHeight: ICON_SIZE + theme.spacing.value(30),
      },
    },
  })),
  disabled: createToolbarStyle((theme) => ({
    toolbarWrapper: {
      ...theme.border.neutral2({ width: 1 }),
    },
    option: {
      background: theme.colors.neutral2,
      'svg, path': {
        color: theme.colors.neutral5 + ' !important',
        stroke: theme.colors.neutral5 + ' !important',
      },
    },
    'option:active': {
      background: theme.colors.neutral2,
      'svg, path': {
        color: theme.colors.neutral5 + ' !important',
        stroke: theme.colors.neutral5 + ' !important',
      },
    },
  })),
  reviewCommentModal: createToolbarStyle((theme) => ({
    toolbarWrapper: {
      borderTopLeftRadius: theme.borderRadius.tiny,
      borderTopRightRadius: theme.borderRadius.tiny,
    },
  })),
  'bg:neutral1': createToolbarStyle((theme) => ({
    toolbarWrapper: {
      backgroundColor: theme.colors.neutral1,
    },
  })),
}
