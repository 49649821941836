import { AnyFile } from '@codeleap/common/dist/tools/Form/types'
import { Disclaimer } from './disclaimers'
import { Journal } from './journal'
import { Profile } from './profile'

type FileExtensions = 'pdf' | 'doc' | 'docx'
export const metadataNames: (keyof Publication)[] = [
  'short_title',
  'ethical_approval',
  'consent',
  'funding_source',
  'conflicts_of_interest',
  'guarantor',
  'provenance_peer_review',
  'data_availability_statement',
  'cover_letter',
]

export enum PublicationStatus {
  saved_in_drafts = 'Saved in drafts',
  submitted_for_review = 'Manuscript submitted',
  under_review = 'Initial Reviews Submitted', // when the first reviewer creates at least a draft
  pending_review = 'Reviewers invited', // when the editor starts searching for reviewers
  reviewers_invited_accepted = 'Reviewers accepted invitation', // one or more reviewers accepted the invitation
  review_submitted = 'Required reviews completed', // when all reviews were submitted
  rejected = 'Rejected',
  accepted = 'Accepted',
  revision_requested = 'Revision requested', // new version (revise)
  revision_in_drafts = 'Revision in drafts', // new version
  revision_completed = 'Revision submitted', // new version
  rejected_transfer = 'Rejected and transferred',
  rejected_resubmit = 'Reject and invite resubmission',
  withdraw = 'Withdrawn'
}

type Section = {
  index: number
  text: string
}

export type PublicationSections = {
  [x in string]: Section;
}

export type PublicationListItem = {
  id: number
  created_datetime: string
  profile: string
  file: string
  file_pdf: string
  have_reviewers: boolean
  file_extension: FileExtensions
}

export type PublicationListReviewer = {
  avatar_url: Profile['avatar']
  profile__email: Profile['email']
  profile__first_name: string
  profile__last_name: string
  profile__id: Profile['id']
}

export type PublicationListEditors = {
  profile__email: Profile['email']
  profile__first_name: string
  profile__last_name: string
}

export type Publication = {
  id: number
  id_slug: string
  created_datetime: string
  profile: Profile
  type?: string
  journal: Journal & { editors: PublicationListEditors[] }
  region?: number
  category?: number
  subcategory?: number
  file: string
  file_pdf?: string
  keywords?: string[]
  sections?: PublicationSections
  title?: string
  short_title?: string
  ethical_approval?: string
  consent?: string
  funding_source?: string
  conflicts_of_interest?: string
  guarantor?: string
  provenance_peer_review?: string
  data_availability_statement?: string
  cover_letter?: string
  reviewers: PublicationListReviewer[]
  status?: keyof typeof PublicationStatus
  status_datetime?: string
  automatic_invite: boolean
  reviewers_quantity: number
  organisation?: string
  last_review: string
  version: number
  editors?: Profile[]
  article_type?: Pick<ArticleCategory, 'id' | 'label'>
  editors_in_chief?: PublicationListEditors[]
  reviews_completed: boolean
  versions: PublicationVersion[]
  editor_comment?: string
  have_history?: boolean
  disclaimers_accepted?: Pick<Disclaimer, 'disclaimer__id'| 'title'>[]
  search_done: boolean
  have_badges?: Record<'manuscripts' | 'history' | 'submit_decision' | 'reviewers_and_invites', number[] | number>
  requires_blindfolded_upload?: boolean
  redacted_file?: AnyFile
  invites_progress?: Record<'accepted_invites' | 'accepted_total' | 'sent_invites' | 'sent_total', number>
  author?: string
}

export type PublicationVersion = {
  id: number
  created_datetime: string
  comment?: string
  ready: boolean
  editor_comment?: string
  article_version: number
  review?: any
  article: number
}

export type PublicationMedia = {
  id: number
  created_datetime: string
  file: string
  file_size: number
  file_category?: number
  article?: Publication['id']
}

export type PublicationAuthor = {
  index?: number // Just for frontend use
  id: number
  email: string
  full_name: string
  organisation: string
  contribution: string
  article: Publication['id']
  tags: ScientificTag['id'][]
  region: string
  created_datetime: string
  order: number
}

export type Category = {
  id: number
  title: string
  description: string
  value: number
  label: string
}

export type SubCategory = Category & { category: number }

export type ScientificTag = {
  id: number
  title: string
  description: string
  value: number
  label: string
}

export type Region = {
  id: number
  value: number
  title: string
  label: string
  short_name: string
}

export type FileCategories = {
  show_on_revision: FileCategory[]
  show_on_submission: FileCategory[]
}

export type FileCategory = {
  id: number
  value: number
  title: string
  label: string
  refers_to_main_document: boolean
  clean_version_of_revised_article_with_changes_accepted: boolean
  response_to_reviewer_or_editor: boolean
}

export type ArticleCategory = {
  id: number
  value: number
  title: string
  label: string
}

export type PublicationPlagiarismStatus = 'generating' | 'not_submitted' | 'submitted' | 'ready' | 'error'

export type PublicationPlagiarismReport = {
  status: string
  metadata: {
    custom: { article: Publication['id'] }
  }
  top_matches: ({
    name: string
    percentage: number
    source_type: string
    matched_word_count_total: number
  })[]
  submission_id: string
  time_generated: Date
  time_requested: Date
  overall_match_percentage: number
  internet_match_percentage: number
  publication_match_percentage: number
  submitted_works_match_percentage: number
  top_source_largest_matched_word_count: number
}

export type PublicationPlagiarismError = 'UNSUPPORTED_FILETYPE' |
  'PROCESSING_ERROR' |
  'CANNOT_EXTRACT_TEXT' |
  'TOO_LITTLE_TEXT' |
  'TOO_MUCH_TEXT' |
  'TOO_MANY_PAGES' |
  'FILE_LOCKED' |
  'CORRUPT_FILE'

export type PublicationPlagiarism = {
  status: PublicationPlagiarismStatus
  report?: PublicationPlagiarismReport
  error_code?: PublicationPlagiarismError
}

export type PublicationStatusBooleans = {
  [key in `is${Capitalize<keyof typeof PublicationStatus>}`]: boolean;
}
