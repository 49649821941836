/* eslint-disable max-len */
import { AppForms, React, Settings, variantProvider } from '@/app'
import { View, Text, Button, UpdateAnswersParams, TextEditor, TextEditorRef } from '@/components'
import { DecisionAnswer, DecisionFormAnswer } from '@/types'
import { FormItemWrapper } from './FormItemWrapper'
import { useRef, useState } from 'react'
import { TypeGuards } from '@codeleap/common'
import { useMemoizedForm } from '@/utils'

export type SubmissionFormProps = {
  formAnswers: DecisionFormAnswer
  publicationId: number
  disabled?: boolean
  updateAnswer: (params: UpdateAnswersParams) => void
}

export type AnswerProps = DecisionAnswer & Omit<SubmissionFormProps, 'updateAnswer' | 'formAnswers'> & { questionId: string; position: number; onChange: SubmissionFormProps['updateAnswer'] }

const answersArray = Array.from({ length: 10 }, (_, i) => i + 1)
answersArray.push(null) // N/A answer - since the value must be null

const Answer = ({ ...data }: AnswerProps) => {
  const { value, id, questionId, review, reviewer_email, reviewer_name, reviewer_id, disabled, publicationId, position, comment: _comment, onChange } = data
  const [currAnswer, setCurrAnswer] = useState(value)
  const textEditorRef = useRef<TextEditorRef>({})

  const form = useMemoizedForm(AppForms.decisionForm, { validateOn: 'change' })

  const handleUpdate = ({ vl = currAnswer, comment = _comment, isValid }: { vl?: number; comment?: string; isValid: boolean }) => {
    onChange({ question: questionId as unknown as number, newValue: vl, comment: comment || textEditorRef?.current?.text, review, type: 'form_answers', isValid })
    if (!TypeGuards.isUndefined(vl)) {
      setCurrAnswer(vl)
    }
  }

  return (
    <FormItemWrapper
      reviewer={{ reviewer_email, reviewer_name, reviewer_id }}
      publicationId={publicationId}
      position={position}
      showMoreInfoIcon={reviewer_email !== Settings.AIReviewer.email}
      isAi={reviewer_email === Settings.AIReviewer.email}
    >
      <View variants={['row', 'gap:1']}>
        {answersArray.map((vl) => {
          const isSelected = vl === currAnswer
          const bgColor = isSelected ? 'backgroundColor:neutral10' : `backgroundColor:neutral1`
          const textColor = isSelected ? 'color:neutral1' : `color:neutral10`
          return <Button
            key={vl}
            debugName={`answer button ${id}-${vl}`}
            variants={['padding:2', 'border-radius:tiny', 'disabled:bare', bgColor, textColor]}
            text={vl?.toString() || 'N/A'}
            onPress={() => handleUpdate({ vl })}
            style={styles.answerButton}
            disabled={disabled}
          />
        })}
      </View>
      {_comment ? (
        <View variants={['bg:neutral1', 'border-radius:tiny']}>
          <TextEditor
            disabled={disabled}
            value={_comment}
            onChangeValue={({ editor }) => {
              const editorValue = editor?.getText?.()
              const isValid = editorValue?.length <= Settings.CoverLetterConfigs.max
              handleUpdate({ comment: editorValue, vl: currAnswer, isValid })
              form.setFieldValue('answer', editorValue)
            }}
            textEditorProps={{
              toolbarComponent: null,
              variants: ['reviewOverviewForm', 'multiline', 'flat:reviewCommentModal', 'flat:disabled'],
              ...form.register('answer'),
            }}
          />
        </View>
      ) : <Text text={_comment} variants={['p3', 'color:neutral10']} />}

    </FormItemWrapper>
  )
}

export const SubmissionForm = (props: SubmissionFormProps) => {
  const {
    formAnswers,
    publicationId,
    updateAnswer,
    disabled,
  } = props

  return (
    <View variants={['column']}>
      {Object.entries(formAnswers)?.map?.(([questionId, question]) => (
        <View key={questionId} variants={['backgroundColor:neutral2', 'column', 'marginBottom:2', 'border-radius:small', 'padding:2']}>

          <Text
            dangerouslySetInnerHTML={{ __html: question.question }}
            variants={['h5', 'semiBold', 'marginBottom:3']}
          />

          <View variants={['gap:3', 'fullWidth', 'column']}>
            {question.answers.map((answer, key) => <Answer
              key={key}
              position={key}
              publicationId={publicationId}
              questionId={questionId}
              onChange={updateAnswer}
              disabled={disabled}
              {...answer}
            />)}
          </View>
        </View>
      ),
      )}
    </View>
  )
}

const ITEM_SIZE = 47

const styles = variantProvider.createComponentStyle((theme) => ({
  answerButton: {
    width: ITEM_SIZE,
    height: ITEM_SIZE,
  },
}), true)
