import { createForm, yup } from '@codeleap/common'
import { Settings } from '../Settings'

// using this form only to show the error on the TextEditor, not to validate the fields
export const decisionForm = () => createForm('decisionForm', {
  comment: {
    type: 'text',
    validate: yup.string().max(Settings.CoverLetterConfigs.max),
  },
  editorComment: {
    type: 'text',
    validate: yup.string().max(Settings.CoverLetterConfigs.max),
  },
  recommendation: {
    type: 'text',
    validate: yup.string().max(Settings.CoverLetterConfigs.max),
  },
  answer: {
    type: 'text',
    validate: yup.string().max(Settings.CoverLetterConfigs.max),
  },
})
