import { modal, useMemoizedForm } from '@/utils'
import { AttachmentsDropzoneProps } from '@/components'
import { AppForms, getOsAlert } from '@/app'
import { useState } from 'react'
import { APIClient } from '@/services'
import { AppStatus } from '@/redux'
import { TicketSubmitted } from './components/TicketSubmitted'
import { SubmitTicketForm } from './components/SubmitTicketForm'
import { useSession } from '@/services/api/session'

const TicketSuccessModal = modal<{ ticketId: number }>({ id: 'ticketSuccessModal' }).content((props) => {
  const { toggle, ticketId } = props
  return (
    <TicketSubmitted ticketId={ticketId} toggle={toggle} />
  )
}).props({
  variants: ['centered'],
  showClose: false,
})

export const SubmitTicketModal = modal({ id: 'submitTicketModal' }).content((props) => {
  const { toggle } = props
  const { profile } = useSession()

  const [newAttachments, setNewAttachments] = useState<AttachmentsDropzoneProps['currentAttachments']>([])

  const form = useMemoizedForm(AppForms.submitTicketForm, {
    validateOn: 'change',
    initialState: {
      name: `${profile.first_name} ${profile.last_name}`,
      email: profile.email,
    },
  })

  const onSubmit = async () => {
    toggle()
    AppStatus.set('loading')
    try {
      const files = newAttachments.reduce((acc, file, index) => {
        acc[`file_${index}`] = file?.file
        return acc
      }, {})

      const response = await APIClient.Help.submitTicket({
        data: {
          name: form.values.name,
          email: form.values.email,
          article: form.values.article,
          manuscriptTitle: form.values.manuscriptTitle,
          description: form.values.description,
        },
        ...files,
      })

      AppStatus.set('done')
      TicketSuccessModal.open({ ticketId: response.ticket_id })
    } catch (e) {
      logger.error('Failed to submit ticket', e)
      if (e.data.code === 'E_ARTICLE_NOT_FOUND') {
        getOsAlert('ticketSubmissionInvalidId')
        AppStatus.set('idle')
        return
      }

      getOsAlert('ticketSubmissionFailed')
      AppStatus.set('idle')
    }
  }

  return (
    <SubmitTicketForm
      form={form}
      toggle={toggle}
      onSubmit={onSubmit}
      newAttachments={newAttachments}
      setNewAttachments={setNewAttachments}
    />
  )
}).props({
  showClose: false,
  variants: ['centered', 'box:responsiveness'],
  styles: {
    body: {
      overflow: 'auto',
      maxHeight: '70vh',

      '@media (max-height: 800px)': {
        maxHeight: '50vh',
      },
    },
  },
})

