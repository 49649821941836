import { View, Text, Button, Touchable, Icon } from '@/components'

type HeaderProps = {
  onClose: () => void
  onSubmit: () => void
  disabled: boolean
}

export const Header = (props: HeaderProps) => {
  const { onClose, onSubmit, disabled } = props

  return (
    <View variants={['row', 'justifySpaceBetween', 'alignCenter']}>
      <Text text={'Submit ticket'} variants={['h3', 'color:neutral9']} />
      <View variants={['row', 'alignCenter', 'gap:2']}>
        <Button debugName='SubmitTicket' text={'Submit'} onPress={onSubmit} variants={['small']} disabled={disabled} debounce={2000} />
        <Touchable debugName={'close:submitTicket'} onPress={onClose}>
          <Icon name='x' debugName={'close:submitTicket'} variants={['size:3', 'neutral10']} />
        </Touchable>
      </View>
    </View>
  )
}
