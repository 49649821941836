const BASE_URL = 'https://premierscience.com/faqs/'

const linkOptions = [
  {
    title: 'What are Premier Science journals?',
    id: 'what-are-premier-science-journals',
  },
  {
    title: 'Why publish in Premier Science journals?',
    id: 'why-publish-in-premier-science-journals',
  },
  {
    title: 'Who should I contact if I need help?',
    id: 'who-should-i-contact-if-i-need-help',
  },
  {
    title: 'How do I submit to a Premier Science journal?',
    id: 'how-do-i-submit-to-a-premier-science-journal',
  },
  {
    title: 'I cannot login to eworkflow to submit my manuscript or my revision?',
    id: 'i-cannot-login-to-eworkflow-to-submit-my-manuscript-or-my-revision',
  },
  {
    title: 'I received a reviewer invitation but it does not show up in my account?',
    id: 'i-received-a-reviewer-invitation-but-it-does-not-show-up-in-my-account',
  },
]

export const FAQUtils = {
  BASE_URL,
  linkOptions,
}
