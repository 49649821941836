import { IS_SSR, variantProvider } from '@/app'
import { ActionIcon } from '@/components'
import { onUpdate, useState } from '@codeleap/common'

export function ScrollToTop() {
  const [disableScrollUp, setDisableScrollUp] = useState(true)

  onUpdate(() => {
    const handleScroll = () => !IS_SSR && setDisableScrollUp(window.scrollY / window.innerHeight < 0.4)

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <ActionIcon
      debugName='scroll to top action icon'
      icon='chevron-up'
      disabled={disableScrollUp}
      onPress={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      style={styles.scrollUpIcon}
      variants={['floating']}
    />
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    scrollUpIcon: {
      ...theme.presets.fixed,
      width: '60px !important',
      height: '60px !important',
      bottom: theme.spacing.value(15),
      right: theme.spacing.value(6),
    },
  }),
  true,
)
