import { api, getOsAlert } from '@/app'
import { getFirebase } from '@/services/firebaseApp'
import { useSession } from './useSession'
import { AppStatus } from '@/redux'
import { Navigation } from '@/utils'

const getCustomToken = async (email: string) => {
  const response = await api.post<{ token: string }>(`/profiles/custom_token/`, {
    email,
  })

  return response.data.token
}

export function useImpersonation() {
  const { logout } = useSession()

  const impersonate = async (email: string) => {
    AppStatus.set('loading')
    try {
      const token = await getCustomToken(email)

      const firebase = await getFirebase()
      AppStatus.set('splash')

      await logout({ withDone: true, navigate: false, showSplash: true })

      await firebase.auth().signInWithCustomToken(token)

      Navigation.navigate('Manuscripts.List')
    } catch (e) {
      if (e?.status) getOsAlert('userNotFoundError')

    }
    // AppStatus.set('idle')
  }

  return impersonate
}
