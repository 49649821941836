import { ModalComposition, ModalPresets } from '@codeleap/web'
import { Theme, variantProvider } from '..'
import { assignTextStyle } from './Text'

const createModalStyle =
  variantProvider.createVariantFactory<ModalComposition>()

const SPACING_VERTICAL = 8

const transition = {
  type: 'tween',
  duration: 0.2,
  ease: 'easeIn',
}

export const AppModalStyles = {
  ...ModalPresets,

  default: createModalStyle((theme) => ({
    wrapper: {
      ...theme.presets.fullHeight,
      ...theme.presets.fullWidth,
      position: 'fixed',
      ...theme.presets.whole,
      zIndex: 999,
      transition: 'visibility 0.2s ease',
      animation: 'fadeIn 0.2s ease-in-out',
    },
    'wrapper:hidden': {
      visibility: 'hidden',
      pointerEvents: 'none',
    },
    'wrapper:visible': {
      visibility: 'visible',
      pointerEvents: 'auto',
    },
    'backdrop:visible:animation': {
      opacity: 0.5,
    },
    'backdrop:hidden:animation': {
      opacity: 0,
    },
    'backdrop:transition': transition,
    'box:transition': transition,
    innerWrapper: {
      ...theme.presets.justifyCenter,
      ...theme.presets.alignStart,
      ...theme.presets.scrollY,
      maxHeight: '100svh',
      minWidth: '100svw',
      ...theme.spacing.paddingVertical(SPACING_VERTICAL),
    },
    backdropPressable: {
      ...theme.presets.whole,
      ...theme.presets.absolute,
      zIndex: 1,
      cursor: 'default',
    },
    backdrop: {
      ...theme.presets.absolute,
      ...theme.presets.whole,
      backgroundColor: theme.colors.neutral10,
      zIndex: -1,
      minHeight: '100%',
      transition: 'opacity 0.2s ease-in-out',
      animation: 'opacity 0.2s ease-in-out',
      cursor: 'default',
    },
    'backdrop:visible': {
      opacity: 0.5,
    },
    'backdrop:hidden': {
      opacity: 0,
    },
    box: {
      backgroundColor: theme.colors.neutral1,
      maxWidth: `85dvw`,
      borderRadius: theme.borderRadius.medium,
      ...theme.presets.column,
      zIndex: 2,
      ...theme.spacing.padding(4),
      ...theme.presets.relative,
      overflow: 'hidden',
      transition: 'opacity 0.2s ease-in-out, transform 0.2s ease-in-out',
      animation: 'scaleIn 0.2s ease-in-out',
    },
    'box:hidden:animation': {
      opacity: 0,

      translateY: -200,
    },
    'box:visible:animation': {
      opacity: 1,
      translateY: 0,
    },
    body: {
      ...theme.presets.column,
    },
    header: {
      ...theme.presets.column,

      ...theme.spacing.marginBottom(3),
      ...theme.presets.alignStart,
      ...theme.presets.justifySpaceBetween,
      backgroundColor: theme.colors.neutral1,
    },
    titleWrapper: {
      ...theme.presets.relative,
      ...theme.presets.fullWidth,
      ...theme.presets.row,
      // ...theme.presets.center,
    },
    title: {
      ...assignTextStyle('h3')(theme).text,
      color: theme.colors.neutral10,
    },
    description: {
      ...theme.presets.fullWidth,
      textAlign: 'center',
      color: theme.colors.neutral8,
      ...theme.spacing.marginTop(1),
      ...assignTextStyle('p1')(theme).text,
    },
    footer: {
      ...theme.spacing.marginTop(2),
    },
    closeButtonTouchableWrapper: {
      padding: theme.spacing.value(0),
      width: 'auto',
      height: 'auto',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      borderRadius: 0,
      background: theme.colors.transparent,
    },
    closeButtonIcon: {
      color: theme.colors.newutral10,
      width: theme.values.iconSize[3],
      height: theme.values.iconSize[3],
    },
  })),
  fullscreen: createModalStyle((theme) => ({
    innerWrapper: {
      padding: 0,
    },
    box: {
      ...theme.presets.fullHeight,
      ...theme.presets.fullWidth,
      maxWidth: 'none',
      borderRadius: 0,
    },
    body: {
      ...theme.presets.fullHeight,
      ...theme.presets.fullWidth,
      maxHeight: `100vh`,
    },
  })),
  centered: createModalStyle((theme) => ({
    innerWrapper: {
      ...theme.presets.center,
    },
    title: {
      margin: 'auto',
    },
  })),
  top: createModalStyle((theme) => ({
    innerWrapper: {
      ...theme.presets.alignStart,
    },
  })),
  scroll: createModalStyle((theme) => ({
    body: {
      overflow: 'auto',
      maxHeight: `calc(85vh - ${theme.spacing.value(SPACING_VERTICAL)}px)`,
    },
  })),

  selectModal: createModalStyle((theme) => ({
    body: {
      overflow: 'auto',
      maxHeight: `calc(85vh - ${theme.spacing.value(SPACING_VERTICAL)}px)`,
    },
    box: {
      width: 700,
      maxHeight: '80vh',
    },
  })),
  'boxPadding:0': createModalStyle((theme) => ({
    box: {
      padding: theme.spacing.value(0),
    },
  })),
  'boxPadding:4': createModalStyle((theme) => ({
    box: {
      padding: theme.spacing.value(4),

      [theme.media.down('small')]: {
        padding: theme.spacing.value(2),
      },
    },
  })),
  'box:responsiveness': createModalStyle((theme) => ({
    box: {
      width: 'calc(100vw - 32px)',
      maxWidth: 800,
    },
  })),
  sectionFiltersModal: createModalStyle((theme) => ({
    body: {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',

      [theme.media.up('small')]: {
        width: '100vw',
        maxWidth: 420,
        minHeight: 'auto',
        maxHeight: '85svh',
      },
    },
    title: {
      display: 'flex',
      justifyContent: 'flex-start',
      ...theme.presets.fullWidth,
    },
  })),
  rejectAndtransferManuscriptModal: createModalStyle((theme) => ({
    body: {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      padding: theme.spacing.value(4),
      paddingBottom: theme.spacing.value(0),
      [theme.media.up('small')]: {
        width: '100vw',
        maxWidth: 700,
        minHeight: 'auto',
        maxHeight: '95svh',
      },
    },
    box: {
      padding: theme.spacing.value(0),
    },
  })),
  announcementsListModal: createModalStyle((theme) => ({
    body: {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      [theme.media.up('midish')]: {
        width: '100vw',
        maxWidth: 420,
        minHeight: 625,
        maxHeight: '75svh',
      },
    },
    box: {
      ...theme.spacing.paddingHorizontal(0),
      [theme.media.up('midish')]: {
        width: 'auto',
      },
    },
    header: {
      ...theme.spacing.paddingHorizontal(3),
    },
  })),
  disclaimerModal: createModalStyle((theme) => ({
    body: {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      padding: theme.spacing.value(4),
      paddingBottom: theme.spacing.value(0),
      [theme.media.up('mid')]: {
        width: '100vw',
        maxWidth: 820,
        minHeight: 500,
      },
    },
    box: {
      padding: theme.spacing.value(0),
    },
  })),
  submitJournalReviewModal: createModalStyle((theme) => ({
    body: {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      paddingBottom: theme.spacing.value(0),
      minHeight: 400,
      maxHeight: '85svh',
      [theme.media.up('small')]: {
        maxWidth: 820,
      },
      [theme.media.down('mid')]: {
        maxWidth: '75vw',
      },
    },
    box: {
      display: 'flex',
      alignItems: 'flex-start',
      ...theme.spacing.padding(0),
      // maxWidth: `67dvw`,
      maxWidth: 900,
    },
    title: {
      ...assignTextStyle('h3')(theme).text,
      fontWeight: '500',
    },
  })),
  replicaOverviewModal: createModalStyle((theme) => ({
    body: {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      paddingBottom: theme.spacing.value(0),
      padding: theme.spacing.value(4),
      flex: 1,
      overflow: 'auto',
      [theme.media.up('mid')]: {
        width: '100vw',
        maxWidth: 800,
      },
    },

    box: {
      padding: theme.spacing.value(0),
      minHeight: 650,
    },
  })),
  reviewOverview: createModalStyle((theme) => ({
    body: {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      paddingBottom: theme.spacing.value(0),
      padding: theme.spacing.value(4),
      minHeight: 400,
      [theme.media.up('mid')]: {
        width: '100vw',
        maxWidth: 820,
      },
    },
    box: {
      padding: theme.spacing.value(0),
    },
  })),
  blindfoldedReview: createModalStyle((theme) => ({
    body: {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      paddingBottom: theme.spacing.value(0),
      padding: theme.spacing.value(4),
      minHeight: 400,
      [theme.media.up('mid')]: {
        width: '100vw',
        maxWidth: 820,
      },
      [theme.media.down('mid')]: {
        maxHeight: '90vh',
      },
    },
    box: {
      padding: theme.spacing.value(0),
    },
    header: {
      ...theme.spacing.paddingTop(4),
      ...theme.spacing.paddingHorizontal(4),
      ...theme.spacing.marginBottom(0),
    },
    title: {
      ...assignTextStyle('h3'),
      ...theme.spacing.margin(0),
    },
  })),
  reviewComment: createModalStyle((theme) => ({
    body: {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      padding: theme.spacing.value(4),
      paddingTop: theme.spacing.value(2),
      [theme.media.up('mid')]: {
        width: '100vw',
        maxHeight: 700,
        maxWidth: 820,
        minHeight: 400,
      },
      [theme.media.down('mid')]: {
        maxHeight: 750,
      },
    },

    box: {
      padding: theme.spacing.value(0),
    },
  })),
  manageEditors: createModalStyle((theme) => {
    const modalWidth = 550
    return {
      box: {
        ...theme.presets.fullWidth,
        maxWidth: modalWidth,
        maxHeight: '80vh',
      },
      body: {
        overflow: 'auto',
        maxHeight: `calc(85vh - ${theme.spacing.value(SPACING_VERTICAL)}px)`,
      },
    }
  }),

  'overflow:visible': createModalStyle((theme) => ({
    box: {
      overflow: 'visible',
    },
  })),
}
