/* eslint-disable max-len */
/* eslint-disable max-lines */
import {
  Text,
  View,
  Touchable,
  TextEditor,
  TextEditorExtensions,
  Button,
  ActionIcon,
  AttachmentsDropzone,
  ActivityIndicator,
  JournalRecommendationInput,
  ExpandableView,
  StatusTag,
} from '@/components'
import { APIClient } from '@/services'
import { AppForms, getOsAlert, React, Theme, variantProvider } from '@/app'
import { TypeGuards, onUpdate, useCallback, useEffect, useForm, useState } from '@codeleap/common'
import { useEditor } from '@tiptap/react'
import { EmptyPlaceholder, ModalProps } from '@codeleap/web'
import { AppStatus } from '@/redux'
import { modal, useIsTablet, useMemoizedForm } from '@/utils'
import Placeholder from '@tiptap/extension-placeholder'

type FormProps = {
  journal: number
  review: number
  article: number
  journalName: string
  articleName: string
  isPreview?: boolean
  reviewID?: number
  publicationID?: number
} & Omit<ModalProps, 'debugName'>

type OnChoicePressItemProps = {
  item: any
  id: number
  value?: number
}

// Refactor when possible - use ReviewsOverview components
export const ReviewerQuestionsForm = modal<FormProps, 'delete' | 'submitted' | 'draft'>({ id: 'reviewerQuestionsForm' }).content((props) => {
  const {
    review: reviewID,
    article: articleID,
    journalName = 'Journal name',
    articleName = 'Article name',
    toggle,
    request,
  } = props

  const { isEditor, isPublisher, isAuthor, isReviewer } = APIClient.Session.useSession()

  const { data: review } = APIClient.Review.useReview({ id: reviewID, article: articleID }) // this will be undefined in case you delete the review
  const { data: publication, query: publicationQuery } = APIClient.Publications.useRetrieve({
    id: props?.article,
    queryOptions: { initialData: null, refetchOnWindowFocus: false },
  })

  const { data: questions, query: questionsQuery } = APIClient.Publishers.useRetrieveQuestions({ journal: publication?.journal?.id })

  const form = useMemoizedForm(AppForms.reviewJournalQuestions, {
    validateOn: 'change',
  })

  const shouldDisableForm = isAuthor || (isEditor || isPublisher)
  const isLoaded = (questionsQuery.isSuccess && !!questions) && (publicationQuery.isSuccess && !!publication)

  const editor = useEditor({
    content: '',
    extensions: [...TextEditorExtensions, Placeholder.configure({ placeholder: 'Enter your comment here...', emptyEditorClass: 'placeholder' })],
    editorProps: {
      transformPastedHTML: (html) => html.replace(/<img.*?>/g, ''),
    },
    editable: !shouldDisableForm,
    onUpdate: ({ editor }) => form.setFieldValue('comments', editor.getText()),
  })

  const text = editor?.getText?.()

  const createReview = APIClient.Review.reviewManager.useCreate()
  const updateReview = APIClient.Review.reviewManager.useUpdate()

  const isTablet = useIsTablet()

  const isReviewSubmitted = !!review && review?.status === 'submitted' && publication?.version === review?.version
  const isReviewDrafted = !!review

  const [articleReviewID, setArticleReviewID] = useState(review?.id)

  const [answers, setAnswers] = useState([])
  const [filteredQuestions, setFilteredQuestions] = useState([]) // I want to die - thats smells like beacon
  const [attachments, setAttachments] = useState([])
  const [tableAttachments, setTableAttachments] = useState([])
  const [mediaToBeDeleted, setMediaToBeDeleted] = useState([])

  const shouldSubmitReviewAgain = review?.version !== publication?.version

  const allQuestionsAnswered = answers?.filter(Boolean).length === questions?.results?.length

  const shouldDisableActions = [
    publication?.status === 'review_submitted',
    (publication?.status === 'under_review' || publication?.status === 'pending_review') && isReviewSubmitted,
  ].some(x => x)

  useEffect(() => {
    if (review) {
      setAnswers(review?.form_answers)
      setFilteredQuestions(review?.form_answers?.reduce((acc, currValue) => {
        if (currValue?.question?.type === 'number') {
          return { ...acc, numbers: [...acc?.numbers, currValue] }
        } else if (currValue?.question?.type === 'multiple-choice') {
          return { ...acc, multipleChoices: [...acc?.multipleChoices, currValue] }
        }
      }, { multipleChoices: [], numbers: [] }))
    }
    if (review && !shouldSubmitReviewAgain) {
      setTableAttachments(review?.media)
      setArticleReviewID(review?.id)
      editor?.setOptions({ editable: !isReviewSubmitted })
    }
  }, [review, isReviewDrafted, isReviewSubmitted, shouldSubmitReviewAgain])

  onUpdate(() => {
    // if (review?.version !== publication?.version) return
    if (editor && review?.comment) {
      editor?.commands?.setContent?.(review?.comment)
    }
  }, [editor, review?.comment, publication?.version])

  const onSubmitReview = async ({ status }) => {

    AppStatus.set('loading')

    try {
      const shouldSubmitAnswers = !!answers?.length && JSON.stringify(answers) !== JSON.stringify(review?.form_answers)
      const shouldSubmitAttachments = !!attachments?.length

      const currentComment = editor?.getText() ? editor?.getHTML?.() : null

      const newAnswers = []
      let currentReview = review

      const shouldUpdateArticleReview = articleReviewID
      const shouldCreateArticleReview = !articleReviewID

      answers.forEach(answer => {
        if (TypeGuards.isNumber(answer?.question)) {
          newAnswers.push({
            question: answer?.question,
            value: answer?.value,
          })
        }
      })

      if (shouldCreateArticleReview) {
        currentReview = await createReview.create({
          article: articleID,
          comment: currentComment,
          status,
        })
      }

      if (shouldUpdateArticleReview) {
        currentReview = await updateReview.update({
          id: articleReviewID,
          article: articleID,
          comment: currentComment,
          status,
        })
      }

      if (mediaToBeDeleted) {
        APIClient.Review.onDeleteMediaAttachment({ media: mediaToBeDeleted })
      }

      if (currentReview) {
        if (shouldSubmitAnswers) {
          APIClient.Review.JournalAnswers({
            review: currentReview?.id,
            answers: [
              ...newAnswers,
            ].filter(Boolean),
          })
        }
        if (shouldSubmitAttachments) {
          await APIClient.Review.postArticleReviewMedia({
            files: attachments,
            review: currentReview?.id,
          })
        }
      }

      AppStatus.set('done')
      if (status === 'draft') {
        request.resolve('draft')
      } else {
        request.resolve('submitted')
      }
    } catch (e) {

    }
  }

  const onDeletedDraft = async () => {
    const response = await APIClient.Review.deleteReviewDraft({ id: articleReviewID, article: articleID })
    if (response) {
      request.resolve('delete')
    }
  }

  const onRemoveAttachment = (media) => {
    setMediaToBeDeleted(state => {
      if (state) {
        return ([...state, media?.id])
      } else {
        return [media?.id]
      }
    })
  }

  const getRecommendationLabel = (answer, choice) => {
    return answer?.question?.choices?.find(e => e.id === choice)?.label
  }

  // OOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOh the one who did that don't know anything about react - the whole modal is fucked up
  const renderItem = useCallback(({ data, index }) => {

    const { question, choices, type, id } = data
    const currentAnswer = answers?.find(a => a?.question?.id === id)

    const isRecommendationQuestion = type === 'multiple-choice'

    const onPressItem = (props: OnChoicePressItemProps) => {

      const { id, item, value: _value } = props
      setAnswers(state => {

        const value = !!_value ? _value : item?.value

        const items = [...state]

        const selectedIndex = state?.findIndex?.(choice => choice?.value === String(value) && id === choice?.question)
        const preSaved = state?.findIndex?.(choice => id === choice?.question?.id)

        const isItemAlreadySelected = selectedIndex !== -1

        //somebody kill me - if I only had time to refactor all this modal
        if (isItemAlreadySelected) {
          delete items[selectedIndex]
        } else if (preSaved > -1) {
          items[preSaved] = { question: id, value: value }
        } else {
          const currentItem = items?.[index]
          if (currentItem) {
            let vl
            if (currentItem.question.type === 'multiple-choice') {
              const choice = currentItem.choice
              vl = currentItem.question.choices.find(c => c.id === choice).value
            } else {
              vl = currentItem?.value
            }
            items[currentItem?.question?.index] = {
              question: currentItem?.question?.id,
              value: vl,
            }
          }
          items[index] = {
            question: id,
            value: value,
          }
        }
        return items
      })
    }

    const renderNumberChoice = (item) => {
      const selectedAnswer = answers?.find(a => (a?.question?.id || a?.question) === item?.question)
      const isItemSelected = selectedAnswer ? (String(item?.value) === String(selectedAnswer?.value)) : null

      const bgColor = isAuthor || (isEditor || isPublisher) ?
        `backgroundColor:${isItemSelected ? 'neutral10' : 'neutral1'}`
        : `backgroundColor:${isItemSelected ? 'neutral10' : 'neutral2'}`
      const textColor = `color:${isItemSelected ? 'neutral1' : 'neutral10'}`

      const ITEM_SIZE = isTablet ? 35 : 47

      return (
        <Touchable
          variants={[bgColor as any, 'padding:2', 'border-radius:tiny']}
          debugName={`Number ${item}`}
          style={{ width: ITEM_SIZE, height: ITEM_SIZE, cursor: (shouldDisableActions || shouldDisableForm) && 'not-allowed' }}
          onPress={() => onPressItem({ item, id })}
          disabled={shouldDisableActions || shouldDisableForm}
          key={item?.value}
        >
          <Text variants={[`p2`, textColor as any]} text={item?.label === 'N/A' ? item?.label : item?.value} />
        </Touchable>
      )
    }

    const renderRecommendationChoice = (item) => {

      const onPressCheckbox = () => {
        onPressItem({ item, id, value: item?.value })
      }

      let isItemSelected = null

      if (answers) {
        if (TypeGuards.isNumber(answers[answers?.length - 1]?.choice) && !answers[answers?.length - 1]?.value) {
          isItemSelected = item?.id === answers[answers?.length - 1]?.choice
        } else {
          isItemSelected = item?.value === answers[answers?.length - 1]?.value
        }
      }

      if (!item?.id) return null

      return (
        <JournalRecommendationInput
          value={isItemSelected}
          onPress={onPressCheckbox}
          label={item?.label}
          disabled={shouldDisableActions}
          key={item?.id}
        />
      )
    }

    if (isRecommendationQuestion && !isAuthor) {
      return (
        <View variants={['column', 'marginTop:1']}>
          <Text variants={[`p2`, 'color:neutral7', 'semiBold', 'marginBottom:1']} text={`Recommendation`} />
          {choices.map((item) => renderRecommendationChoice(item))}
        </View>
      )
    }

    if (isRecommendationQuestion && isAuthor) {
      return
    }

    // Use this when we need the html to render - remove it later
    const html = `<span>${index + 1}. &nbsp;</span><span style="flex-direction:row">${question}</span>`
    const label = <Text variants={[`p2`, 'color:neutral7', 'marginBottom:1', 'row', 'alignStart']} dangerouslySetInnerHTML={{ __html: html }} />

    const variants = isAuthor || (isEditor || isPublisher) ? ['backgroundColor:neutral2', 'padding:2', 'border-radius:smallish'] : ['']

    return (
      <View variants={['column', 'gap:2', ...variants]}>
        {label}
        <View style={styles.numberChoicesWrapper}>
          {choices?.map((item) => renderNumberChoice(item))}
          {currentAnswer?.comment ? <Text text={currentAnswer?.comment} variants={['p1', 'color:neutral9']}/> : null}
        </View>
      </View>
    )
  }, [answers, isTablet])

  const renderContent = () => {
    const showAnswers = review?.form_answers?.length && !isReviewer
    const _questions = showAnswers ? answers : questions?.results

    const questionsWithNullValues = _questions?.map((question) => {

      if ((showAnswers && question?.question?.type === 'number') || (!review?.form_answers?.length && isReviewer && question?.type === 'number')) {
        return {
          ...(showAnswers ? question?.question : question),
          choices: [
            ...(showAnswers ? question?.question?.choices : question?.choices),
            {
              created_datetime: new Date().toISOString(),
              id: null,
              label: 'N/A',
              question: showAnswers ? question?.question?.id : question?.id,
              value: null,
            },
          ],
        }
      }

      return question
    })

    return (
      <View variants={['column', 'gap:2']}>
        {questionsWithNullValues?.map?.((item, index) => (item?.question?.type !== 'multiple-choice' && item?.type !== 'multiple-choice') || isReviewer ? renderItem({ data: item, index }) : null)}
      </View>
    )

  }

  if (!isLoaded) {
    return <View variants={['center', 'fullWidth', 'fullHeight', 'marginVertical:auto']}>
      <ActivityIndicator debugName='SectionFallback' />
    </View>
  }

  return (
    <View variants={['paddingHorizontal:4', 'paddingTop:2', 'paddingBottom:4', 'column', 'fullHeight', 'fullWidth', 'scrollY']}>

      <View variants={['flex', 'fullWidth', 'alignCenter', 'justifySpaceBetween']}>
        <Text variants={['h3', 'color:neutral9']} text={isReviewer && isReviewSubmitted ? `Submit review` : 'Review'} />
        <ActionIcon
          icon={'x'}
          onPress={toggle}
          debugName='Review Questions Modal - Close modal icon'
          variants={['transparent', 'neutral10']}
        />
      </View>

      <View style={styles.scroll} variants={['fullWidth', 'gap:5']}>
        {!isAuthor && (
          <View variants={['column', 'gap:2']}>
            <View variants={['alignCenter', 'gap:1', 'row', 'fullWidth']} style={styles.infosWrapper}>
              <Text variants={[`h5`, 'color:neutral7', 'semiBold']} text={`Currently reviewing:`} />
              <Text variants={[`h5`, 'color:neutral10', 'semiBold', 'wrap', 'breakWord']} text={articleName} />
            </View>

            <View variants={['alignCenter', 'gap:1']} style={styles.infosWrapper}>
              <Text variants={[`h5`, 'color:neutral7', 'semiBold']} text={`Article type:`} />
              <Text variants={[`h5`, 'color:neutral10', 'semiBold']} text={publication?.article_type?.label} />
            </View>

            <View variants={['alignCenter', 'gap:1', 'row', 'fullWidth']} style={styles.infosWrapper}>
              <Text variants={[`h5`, 'color:neutral7', 'semiBold']} text={`Journal:`} />
              <Text variants={[`h5`, 'color:neutral10', 'semiBold', 'wrap', 'breakWord']} text={journalName} />
            </View>

            {((isEditor || isPublisher) && review?.reviewer?.first_name) && <View variants={['alignCenter', 'gap:1']}>
              <Text variants={[`h5`, 'color:neutral7', 'semiBold']} text={`Reviewer:`} />
              <Text variants={[`h5`, 'color:neutral10', 'semiBold']} text={review?.reviewer?.first_name + ' ' + review?.reviewer?.last_name} />
            </View>}

          </View>
        )}

        {isAuthor || (isEditor || isPublisher) ? filteredQuestions?.multipleChoices?.map((answer, index) => {
          const answerLabel = TypeGuards.isNumber(answer?.choice) ? getRecommendationLabel(answer, answer?.choice) : ''
          return (
            <View key={index} variants={['backgroundColor:neutral2', 'border-radius:smallish', 'padding:2', 'column', 'gap:2']}>
              <Text dangerouslySetInnerHTML={{ __html: answer?.question?.question }} />
              <View variants={['column', 'gap:2']}>
                {answerLabel ? <StatusTag label={answerLabel} /> : null}
                {answer?.comment ? <Text text={answer?.comment} variants={['p1', 'color:neutral9']}/> : null}
              </View>
            </View>
          )
        }) : null}

        <ExpandableView initialState={true} title='Submission form'>
          <>
            {isReviewer && <Text variants={[`h5`, 'semiBold', 'color:neutral8', 'marginBottom:2']} text={`Form section name`} />}
            {renderContent()}
          </>
        </ExpandableView>

        <ExpandableView initialState title={isAuthor || (isEditor || isPublisher) ? 'Reviewer comment' : 'Comment'}>
          {isReviewer && !isReviewSubmitted ? (
            <TextEditor
              textEditorProps={{
                editor,
                ...form.register('comments'),
                variants: ['reviewerQuestionsForm', 'marginBottom:5'],
              }}
              toolbarComponentProps={{
                editor,
                excludeIds: ['image', 'fileComponent'],
              }}
            />
          ) : null}
          {shouldDisableForm && text ? (
            <View variants={['column', 'gap:1', 'border-radius:smallish', 'backgroundColor:neutral2', 'padding:2']}>
              <TextEditor
                textEditorProps={{
                  editor,
                  ...form.register('comments'),
                  variants: ['flat', 'flat:disabled', 'disabledPlain'],
                }}
                toolbarComponentProps={{ editor, hide: true }}
              />
            </View>
          ) : null}
          {isReviewSubmitted && !text ? <EmptyPlaceholder icon='file-text' variants={['table']} title='No comment provided' /> : null}
        </ExpandableView>

        <ExpandableView title='Attachments' initialState styles={{ wrapper: styles.attachmentsWrapper }}>
          {tableAttachments?.length || !isReviewSubmitted ? (
            <AttachmentsDropzone
              currentAttachments={attachments}
              setCurrentAttachments={setAttachments}
              showTable={!!tableAttachments?.length}
              tableAttachments={tableAttachments}
              setTableAttachments={setTableAttachments}
              showDelete
              showDownload
              showDropzone={isReviewer && !isReviewSubmitted}
              onDelete={onRemoveAttachment}
              publication={publication?.id}
              enableCategorySelector={false}
              dropzoneProps={{
                variants: ['ellipsis'],
              }}
            />

          ) : (
            <EmptyPlaceholder icon='file' variants={['table']} title='No Attachment provided.' />
          )}
        </ExpandableView>

      </View>

      <View variants={['fullWidth', 'marginTop:3', 'justifySpaceBetween']} responsiveVariants={{ small: ['column'] }}>
        {!isReviewSubmitted ? (
          <>
            <Button
              variants={[`backgroundColor:neutral2`, 'padding:1.5', 'large']}
              responsiveVariants={{ small: ['small'] }}
              icon={'trash'}
              text={`Delete`}
              disabled={!isReviewDrafted || shouldDisableActions}
              debugName={`Review Questions Modal - Delete Review`}
              styles={{ text: styles.deleteButtonText, icon: styles.deleteButtonIcon }}
              onPress={() => getOsAlert('deleteJournalReview', { onDelete: onDeletedDraft })}
            />

            <View variants={['gap:2']} responsiveVariants={{ small: ['column', 'marginTop:2'] }}>
              <Button
                variants={[`backgroundColor:neutral2`, 'padding:1.5', 'large']}
                responsiveVariants={{ small: ['small'] }}
                text={`Save draft`}
                debugName={`Review Questions Modal - Save Draft`}
                styles={{ text: styles.saveDraftButtonText }}
                disabled={!answers?.length || shouldDisableActions || !form.isValid}
                onPress={() => onSubmitReview({ status: 'draft' })}
              />
              <Button
                variants={['padding:1.5', 'large']}
                responsiveVariants={{ small: ['small'] }}
                text={`Submit review`}
                disabled={!allQuestionsAnswered || shouldDisableActions || !form.isValid}
                debugName={`Review Questions Modal - Submit Review`}
                onPress={() => getOsAlert('onConfirmSubmit', { onSubmit: () => onSubmitReview({ status: 'submitted' }) })}
              />
            </View>
          </>
        ) : null}
      </View>

    </View>
  )
})

const styles = variantProvider.createComponentStyle((theme) => ({
  scroll: {
    ...theme.spacing.paddingBottom(0),
    ...theme.spacing.paddingRight(1),
    ...theme.presets.column,
    ...theme.presets.fullWidth,
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  infosWrapper: {
    flexWrap: 'wrap',
    wordBreak: 'break-all',
  },
  loadingWrapper: {
    minHeight: 200,
  },
  numberChoicesWrapper: {
    flexWrap: 'wrap',
    ...theme.spacing.gap(2),
  },
  deleteButtonText: {
    color: theme.colors.destructive2,
    ...theme.spacing.marginLeft(1),
  },
  deleteButtonIcon: {
    color: theme.colors.destructive2,
  },
  saveDraftButtonText: {
    color: theme.colors.neutral10,
  },
  body: {
    minWidth: 1000,
    ...theme.presets.fullWidth,

    [theme.media.up('small')]: {
      minWidth: 820,
    },

    [theme.media.down('largeish')]: {
      minWidth: '100%',
    },
  },
  attachmentsWrapper: {
    ...Theme.spacing.paddingBottom(2),
  },

}), true)

ReviewerQuestionsForm.props({
  showClose: false,
  withScrollContainer: false,
  closable: true,
  variants: ['submitJournalReviewModal', 'centered'],
  styles: {
    body: styles.body,
  },
})
