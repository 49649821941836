import { createForm, yup } from '@codeleap/common'

export const submitTicketForm = createForm('submitTicketForm', {
  name: {
    type: 'text',
    placeholder: 'John Doe',
    label: 'Name',
    validate: yup.string().required().min(3)
      .max(100),
  },
  email: {
    type: 'text',
    placeholder: 'Email@example.com',
    label: 'E-mail',
    validate: yup.string().email().required(),
  },
  article: {
    type: 'text',
    placeholder: 'Type an ID',
    label: 'Manuscript ID (Optional)',
    validate: yup.string().notRequired().max(100),
  },
  manuscriptTitle: {
    type: 'text',
    placeholder: 'Type a title',
    label: 'Manuscript title (Optional)',
    validate: yup.string().notRequired().max(100),
  },
  description: {
    type: 'text',
    placeholder: 'Type your comment here',
    label: 'Description',
    validate: yup.string().required().min(15)
      .max(5000),
  },
})
