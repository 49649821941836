import { View, Text, Button } from '@/components'

type TicketSubmittedProps = {
  ticketId: number
  toggle: () => void
}

export const TicketSubmitted = (props: TicketSubmittedProps) => {
  const { ticketId, toggle } = props

  return (
    <View variants={['column', 'gap:3']}>
      <Text text={'Ticket successfully submitted'} variants={['color:neutral9', 'h3']} />
      <View variants={['column', 'gap:3', 'justifyCenter']}>
        <Text text={`#${ticketId}`} variants={['h1', 'color:neutral9', 'textCenter']} />
        <Text
          text={'This is your ticket number. If you need a follow-up, please contact us via e-mail.'}
          variants={['color:body', 'p1', 'textCenter']}
          style={{ maxWidth: 400 }}
        />
      </View>
      <Button debugName={'Close submit ticket'} text={'Close'} onPress={toggle} variants={['fullWidth']} />
    </View>
  )
}
