import { api } from '@/app'
import { HelpGuide } from '@/types'
import { ReactQuery } from '@codeleap/common'

type SubmitTicketParams = {
  data: {
    name: string
    email: string
    article: number
    manuscriptTitle: string
    description: string
  }
  [key: `file_${number}`]: File | string
}

const BASE_URL = 'crm/'

const getHelpGuides = async () => {
  const response = await api.get<HelpGuide[]>(`${BASE_URL}help/`)

  return response.data
}

export const submitTicket = async (data: SubmitTicketParams) => {
  const response = await api.post<{ ticket_id: number }>(`${BASE_URL}request_support/`, data, { multipart: true })

  return response.data
}

export const useHelpGuides = () => {
  const query = ReactQuery.useQuery({
    queryKey: ['help_guides'],
    queryFn: getHelpGuides,
    initialData: null,
  })

  return { guides: query.data, query }
}
