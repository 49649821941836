import { AppForms, React, Settings } from '@/app'
import { View, Text, JournalRecommendationInput, UpdateAnswersParams, TextEditor, TextEditorRef, StatusTag } from '@/components'
import { DecisionRecommendation, JournalQuestionChoice } from '@/types'
import { FormItemWrapper } from './FormItemWrapper'
import { useRef, useState } from 'react'
import { APIClient } from '@/services'
import { useMemoizedForm } from '@/utils'

export type RecommendationFormProps = {
  recommendations: DecisionRecommendation
  publicationId: number
  disabled?: boolean
  updateAnswer: (params: UpdateAnswersParams) => void
}

export type AnswerProps = DecisionRecommendation[number]['answers'][number]
& Omit<RecommendationFormProps, 'updateAnswer' | 'recommendations'>
& {
  questionId: string
  position: number
  choices: Pick<JournalQuestionChoice, 'id' | 'label' | 'value'>[]
  isAuthor: boolean
  onChange: RecommendationFormProps['updateAnswer']
}

const Answer = ({
  choice_value,
  choices,
  questionId,
  review,
  reviewer_email,
  reviewer_name,
  reviewer_id,
  disabled,
  publicationId,
  position,
  isAuthor,
  comment: _comment,
  onChange,
}: AnswerProps) => {
  const [currAnswer, setCurrAnswer] = useState(choice_value)
  const textEditorRef = useRef<TextEditorRef>({})

  const form = useMemoizedForm(AppForms.decisionForm, { validateOn: 'change' })

  const handleUpdate = ({ vl = currAnswer, comment }: { vl?: number; comment?: string }) => {
    onChange({ question: questionId as unknown as number, newValue: vl, comment: comment || textEditorRef?.current?.text, review, type: 'recommendations' })
    if (vl) {
      setCurrAnswer(vl)
    }
  }

  return (
    <FormItemWrapper
      reviewer={{ reviewer_email, reviewer_name, reviewer_id }}
      publicationId={publicationId}
      position={position}
      showMoreInfoIcon={reviewer_email !== Settings.AIReviewer.email}
      isAi={reviewer_email === Settings.AIReviewer.email}
    >
      <View variants={['row', 'gap:1']}>
        {isAuthor ? (
          <StatusTag label={choice_value} />
        ) : (
          <View variants={['column']}>
            {choices.map(choice => (
              <JournalRecommendationInput
                value={currAnswer === choice?.value}
                label={choice?.label}
                onPress={() => handleUpdate({ vl: choice.value })}
                disabled={disabled}
                key={choice?.id}
              />
            ))}
          </View>
        )}
      </View>
      {_comment ? (
        <View variants={['bg:neutral1', 'border-radius:tiny']}>
          <TextEditor
            ref={textEditorRef}
            disabled={disabled}
            value={
              _comment || ''}
            onChangeValue={({ editor }) => {
              handleUpdate({ comment: editor?.getText?.(), vl: currAnswer })
              form.setFieldValue('recommendation', editor?.getText?.())
            }}
            textEditorProps={{
              toolbarComponent: null,
              variants: ['reviewOverviewForm', 'multiline', 'flat:reviewCommentModal', 'flat:disabled'],
              ...form.register('recommendation'),
            }}
          />
        </View>
      ) : <Text text={_comment} variants={['p3', 'color:neutral10']} />}
    </FormItemWrapper>
  )
}

export const RecommendationForm = (props: RecommendationFormProps) => {
  const {
    recommendations,
    publicationId,
    updateAnswer,
    disabled,
  } = props
  const { isAuthor } = APIClient.Session.usePermissions()

  return (
    <View variants={['column']}>
      {Object.entries(recommendations)?.map?.(([questionId, question]) => (
        <View key={questionId} variants={['backgroundColor:neutral2', 'column', 'marginBottom:2', 'border-radius:small', 'padding:2']}>

          <Text
            dangerouslySetInnerHTML={{ __html: question.question }}
            variants={['h5', 'semiBold', 'marginBottom:3']}
          />

          <View variants={['gap:3', 'fullWidth', 'column']}>
            {question.answers.map((answer, key) => <Answer
              key={key}
              position={key}
              publicationId={publicationId}
              questionId={questionId}
              onChange={updateAnswer}
              disabled={disabled}
              isAuthor={isAuthor}
              choices={question.choices}
              {...answer}
            />)}
          </View>

        </View>
      ),
      )}
    </View>
  )
}
