import { AppForms, Settings } from '@/app'
import { View, TextInput, ExpandableView, AttachmentsDropzone, AttachmentsDropzoneProps } from '@/components'
import { useMemoizedForm } from '@/utils'
import { Header } from './Header'

type SubmitTicketFormProps = {
  form: ReturnType<typeof useMemoizedForm<typeof AppForms.submitTicketForm, any>>
  toggle: () => void
  onSubmit: () => void
  newAttachments: AttachmentsDropzoneProps['currentAttachments']
  setNewAttachments: AttachmentsDropzoneProps['setCurrentAttachments']
}

export const SubmitTicketForm = (props: SubmitTicketFormProps) => {
  const { form, toggle, onSubmit, newAttachments, setNewAttachments } = props

  const validator = (attachments) => {
    const invalidFiles = attachments.find(attachment => {
      const ext = attachment.file.name.split('.').pop().toLowerCase()
      if (Settings.FilePickerConfigs.BlockedEmailExtensions.includes(ext)) {
        return attachment
      } else return
    })

    if (invalidFiles) {
      return { message: `Invalid file extension: ${invalidFiles.file.name}. Please upload only supported formats.`, valid: false }
    }

    const sizeValidation = AppForms.fileSizeValidator(attachments)
    if (!sizeValidation.valid) return sizeValidation

    return { message: '', valid: true }
  }

  return (
    <View variants={['column']} style={{ maxHeight: '100%', overflowY: 'auto' }}>
      <Header
        onClose={toggle}
        onSubmit={onSubmit}
        disabled={!form.isValid}
      />
      <View variants={['column', 'paddingTop:3']}>
        <TextInput
          {...form.register('name')}
        />
        <TextInput
          {...form.register('email')}
          disabled={!!form.values.email}
        />
        <TextInput
          {...form.register('article')}
        />
        <TextInput
          {...form.register('manuscriptTitle')}
        />
        <TextInput
          {...form.register('description')}
          multiline
        />
      </View>

      <ExpandableView initialState={true} title={'Attachments (Optional)'}>
        <AttachmentsDropzone
          showTable={false}
          showDropzone={true}
          showFileCategory={false}
          enableCategorySelector={false}
          currentAttachments={newAttachments}
          setCurrentAttachments={setNewAttachments}
          dropzoneProps={{
            placeholder: 'Please choose a DOCX or PDF file for upload',
          }}
          prepareFilesForUploadProps={{ validator }}
        />
      </ExpandableView>
    </View>
  )
}
